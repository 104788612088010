'use client';
import { useAtom, useSetAtom } from 'jotai';
import {
  cartAtom,
  shopifyCartIdWithStorageAtom,
  isRemovingLineItemAtom,
  isRemovingOneItemAtom,
  isAddingOneAtom,
  isCartSlideOutOpenAtom,
  userFormAtom,
} from '@/store/cartStore';
import { getProduct } from '@/sanity/services/productService';
import {
  mixpanelRemoveFromCartEvent,
  mixpanelAddToCartEvent,
} from '@/lib/mixpanel/services/client';
import {
  BillingFormType,
  CartResponse,
  CartType,
  DeliveryFormType,
  Product,
  UserFormType,
} from '@/types/types';
import { datadogLogs } from '@datadog/browser-logs';
import { useStatsigClient } from '@statsig/react-bindings';
import {
  soleProprietorProductSwapMap,
  soleProprietorReverseProductSwapMap,
} from '@/lib/shopify/constants';
import {
  addCartItem,
  addDeliveryAddress,
  addDiscountCode,
  createCheckoutCart,
  getCart,
  removeCartItem,
  updateCartItem,
} from '@/actions/cartActions';

const TAX_RATE_ZA = 15;
const DELIVERY_FEE = 150;
const IK_DEBIT_DELIVERY_FEE = 125;

const useCart = () => {
  const [cart, setCart] = useAtom(cartAtom);
  const { logEvent } = useStatsigClient();

  const [isRemovingLineItem, setIsRemovingLineItem] = useAtom(
    isRemovingLineItemAtom
  );
  const [isRemovingOneItem, setIsRemovingOneItem] = useAtom(
    isRemovingOneItemAtom
  );
  const [isAddingOneItem, setIsAddingOneItem] = useAtom(isAddingOneAtom);
  const setIsCartOpen = useSetAtom(isCartSlideOutOpenAtom);

  const [cartId, setCartIdWithStorage] = useAtom(shopifyCartIdWithStorageAtom);

  const setUserAtom = useSetAtom(userFormAtom);

  const createCart = async () => {
    const shopifyCheckout = await createCheckoutCart();

    if (!shopifyCheckout) {
      return;
    }
    setCartIdWithStorage(shopifyCheckout.id);
    updateCart([], shopifyCheckout);
    return shopifyCheckout;
  };

  const fetchCart = async (cartParamId?: string) => {
    if (!cartId && !cartParamId) {
      return await createCart();
    } else if (cartId !== cart?.id || cartParamId !== cart?.id) {
      let cartShopId = cartParamId ?? cartId;
      const shopifyCheckout = await getCart(cartShopId);

      if (!shopifyCheckout) {
        return;
      }
      const lineItems = await fetchLineItems(shopifyCheckout?.lines || []);

      const lineItemsWithProduct = lineItems.filter((item) => item.product);
      const lineItemsWithoutProduct = lineItems.filter((item) => !item.product);
      if (lineItemsWithoutProduct.length > 0 && cartShopId) {
        const lineItemsToRemove = lineItemsWithoutProduct.map(
          (item) => item.id
        );
        await removeCartItem(cartShopId, lineItemsToRemove);
      }
      updateCart(lineItemsWithProduct, shopifyCheckout);
      return shopifyCheckout;
    }
  };

  const fetchLineItems = async (lineItems: CartResponse['lines']) => {
    const lineItemsData = lineItems.edges.map((item) => item.node);
    const shopifyLineItems = lineItemsData.map(
      (item: {
        merchandise: {
          id: string;
          product: {
            handle: string;
          };
        };
        id: string;
        quantity: number;
      }) => ({
        productHandle: item?.merchandise?.product?.handle ?? '',
        variantGid: item?.merchandise?.id,
        quantity: item.quantity,
        id: item?.id,
      })
    );

    const fetchedLineItems = await Promise.all(
      shopifyLineItems.map((item) => getProduct(item.productHandle))
    );

    const response = fetchedLineItems.map((product, index) => ({
      product: {
        ...(product as Product),
        variantGid: shopifyLineItems[index].variantGid,
      },
      id: shopifyLineItems[index].id,
      quantity: shopifyLineItems[index].quantity,
      variantGid: shopifyLineItems[index].variantGid,
    }));

    return response;
  };

  /**
   * Initializes the process of adding an item to the cart.
   *
   * @param theCartId - The ID of the cart.
   */
  const initAddToCart = async (
    theCartId: string | null,
    variants: {
      variantId: string;
      quantity?: number;
    }[]
  ) => {
    if (theCartId === null) return;

    const variantIds = variants.map((item) => item.variantId);
    setIsAddingOneItem(variantIds);

    const itemsToAdd = variants.map(({ variantId, quantity = 1 }) => ({
      merchandiseId: variantId,
      quantity,
    }));

    try {
      const shopifyCheckout = await addCartItem({
        cartId: theCartId,
        lines: itemsToAdd,
      });

      if (!shopifyCheckout) {
        return;
      }

      const lineItems = await fetchLineItems(shopifyCheckout?.lines || []);

      const addedLineItems = lineItems.filter(
        (item) =>
          item.product?.variantGid &&
          variantIds.includes(item.product.variantGid)
      );

      updateCart(lineItems, shopifyCheckout);
      setIsAddingOneItem(false);

      addedLineItems.forEach((item) => {
        if (item.product) {
          mixpanelAddToCartEvent(item);
          logEvent('add_to_cart', item.product.sanityTitle, {
            id: item.product.id,
            name: item.product.sanityTitle,
            price: `${item.product.price}`,
            event_source: 'Online',
            currency_code: 'ZAR',
            category: item.product.categorySlug.replace(/-/g, ' '),
          });
        }
      });
    } catch (error) {
      console.error('Error adding item to cart:', error);
      setIsAddingOneItem(false);
      throw error;
    }
  };

  const getVariantGId = (variantId: string): string => {
    const isSwappedItemInCart = cart?.lineItems.some(
      (item) =>
        soleProprietorReverseProductSwapMap[item.product.variantGid] ===
        variantId
    );
    if (isSwappedItemInCart) {
      return soleProprietorProductSwapMap[variantId] ?? variantId;
    }
    return variantId;
  };

  const addItem = async (
    variant: {
      variantId: string;
      quantity?: number;
    },
    clearExistingItemsFirst: boolean = false
  ) => {
    const variantGId = getVariantGId(variant.variantId);

    try {
      if (clearExistingItemsFirst) {
        const shopifyResponse = await createCart();
        if (!shopifyResponse) {
          return;
        }
        await initAddToCart(shopifyResponse.id, [
          {
            variantId: variantGId,
          },
        ]);
      } else {
        await initAddToCart(cartId, [
          {
            variantId: variantGId,
          },
        ]);
      }
    } catch (error: any) {
      //try to parse error and handle accordingly if fails bubble up error
      try {
        const errorResponse = JSON.parse(error.message);
        const field = errorResponse[0].field[0];
        const message = errorResponse[0].message;
        if (field === 'checkoutId' && message === 'Checkout does not exist') {
          const shopifyResponse = await createCart();
          if (!shopifyResponse) {
            return;
          }
          await initAddToCart(shopifyResponse.id, [
            {
              variantId: variantGId,
            },
          ]);
        }
      } catch (error) {
        console.error('Error adding item to cart:', error);
        throw error;
      }
    }
  };

  const addItems = async (
    variants: {
      variantId: string;
      quantity?: number;
    }[],
    clearExistingItemsFirst: boolean = false
  ) => {
    const variantGIds = variants.map(({ variantId, quantity = 1 }) => {
      return {
        variantId: getVariantGId(variantId),
        quantity: quantity,
      };
    });

    try {
      if (clearExistingItemsFirst) {
        const shopifyResponse = await createCart();
        if (!shopifyResponse) {
          return;
        }
        await initAddToCart(shopifyResponse.id, variantGIds);
      } else {
        await initAddToCart(cartId, variantGIds);
      }
    } catch (error: any) {
      //try to parse error and handle accordingly if fails bubble up error
      try {
        const errorResponse = JSON.parse(error.message);
        const field = errorResponse[0].field[0];
        const message = errorResponse[0].message;
        if (field === 'checkoutId' && message === 'Checkout does not exist') {
          const shopifyResponse = await createCart();
          if (!shopifyResponse) {
            return;
          }
          await initAddToCart(shopifyResponse.id, variantGIds);
        }
      } catch (error) {
        console.error('Error adding item to cart:', error);
        throw error;
      }
    }
  };

  const updateItem = async (variantId: string, quantity: number) => {
    if (quantity > 0) {
      setIsAddingOneItem(variantId);
    } else {
      setIsRemovingOneItem(variantId);
    }

    if (cartId === null) {
      setIsAddingOneItem(false);
      setIsRemovingOneItem(false);
      return;
    }
    const shopifyCheckout = await getCart(cartId);
    if (!shopifyCheckout?.lines) {
      setIsAddingOneItem(false);
      setIsRemovingOneItem(false);
      return;
    }
    const lineItem = shopifyCheckout.lines.edges.find(
      (item: {
        node: {
          id: string;
        };
      }) => item.node && item.node.id === variantId
    )?.node;

    if (!lineItem) {
      throw new Error('Item not found in cart');
    }

    if (quantity < 0 && lineItem.quantity === 1) {
      return;
    }

    const newQuantity = lineItem.quantity + quantity;

    const shopifyCheckoutUpdated = await updateCartItem(cartId, [
      { id: lineItem.id, quantity: newQuantity },
    ]);

    if (!shopifyCheckoutUpdated) {
      return;
    }

    const lineItems = await fetchLineItems(shopifyCheckoutUpdated?.lines ?? []);

    let mixpanelLineItem = lineItems.find(
      (item) => item.product && item.product.variantGid === variantId
    );
    if (mixpanelLineItem && mixpanelLineItem.product) {
      if (quantity > 0) {
        mixpanelAddToCartEvent(mixpanelLineItem);
        logEvent('add_to_cart', mixpanelLineItem.product.sanityTitle, {
          id: mixpanelLineItem.product.id,
          name: mixpanelLineItem.product.sanityTitle,
          price: `${mixpanelLineItem.product.price}`,
          event_source: 'Online',
          currency_code: 'ZAR',
          category: mixpanelLineItem.product.categorySlug.replace(/-/g, ' '),
        });
      } else {
        mixpanelLineItem.quantity = newQuantity;
        mixpanelRemoveFromCartEvent(mixpanelLineItem);
        logEvent('remove_from_cart', mixpanelLineItem.product.sanityTitle, {
          id: mixpanelLineItem.product.id,
          name: mixpanelLineItem.product.sanityTitle,
          price: `${mixpanelLineItem.product.price}`,
          event_source: 'Online',
          currency_code: 'ZAR',
          category: mixpanelLineItem.product.categorySlug.replace(/-/g, ' '),
        });
      }
    }

    updateCart(lineItems, shopifyCheckoutUpdated);
    setIsAddingOneItem(false);
    setIsRemovingOneItem(false);
  };

  const removeItem = async (variantId: string) => {
    if (cartId === null) return;
    setIsRemovingLineItem(variantId);
    const shopifyCheckout = await getCart(cartId);

    if (!shopifyCheckout?.lines?.edges) {
      return;
    }

    const lineItem = shopifyCheckout?.lines?.edges.find(
      (item: {
        node: {
          id: string;
        };
      }) => item.node && item.node.id === variantId
    )?.node;

    if (!lineItem) {
      return;
    }

    const shopifyCheckoutUpdated = await removeCartItem(cartId, [lineItem.id]);

    if (!shopifyCheckoutUpdated) {
      return;
    }
    const lineItems = await fetchLineItems(shopifyCheckoutUpdated.lines);

    const removedItem = cart?.lineItems.find(
      (item) => item.product.variantGid === variantId
    );
    if (removedItem) mixpanelRemoveFromCartEvent(removedItem);

    if (lineItems.length === 0) setIsCartOpen(false);

    setIsRemovingLineItem(false);
    updateCart(lineItems, shopifyCheckoutUpdated);
  };

  const addDiscount = async (
    discountCode: string
  ): Promise<{ success: boolean; message: string }> => {
    if (cartId === null) return { success: false, message: 'Cart not found' };
    const shopifyCheckoutUpdated = await addDiscountCode(cartId, discountCode);

    let message = 'Invalid discount code';

    // if error message is returned from shopify
    //@ts-ignore
    const errors = shopifyCheckoutUpdated.userErrors;
    if (
      (errors && errors[0]?.message) ||
      !shopifyCheckoutUpdated?.discountCodes?.[0]?.applicable
    ) {
      return {
        success: false,
        message: message,
      };
    }

    if (
      !shopifyCheckoutUpdated?.discountCodes ||
      shopifyCheckoutUpdated?.discountCodes.length === 0
    ) {
      return {
        success: false,
        message: 'Discount code requirement not met',
      };
    }

    updateCart(cart?.lineItems ?? [], shopifyCheckoutUpdated);
    return { success: true, message: 'Discount has been applied to your cart' };
  };

  const calculateTotalTax = (totalPrice: number) => {
    const originalNonVATPrice = totalPrice / (1 + TAX_RATE_ZA / 100);
    return totalPrice - originalNonVATPrice;
  };

  const productIsDebitCard = (item: {
    product: {
      slug: string;
      categorySlug: string;
    };
  }) => {
    return item?.product?.slug === 'tyme-debit-card';
  };
  const calculateDeliveryPrice = (lineItems: any[]) => {
    const hasCardMachine = lineItems.some((item) =>
      ['card-machines', 'point-of-sale'].includes(item?.product?.categorySlug)
    );

    if (hasCardMachine) return 0;

    const hasIkDebit = lineItems.some((item) => productIsDebitCard(item));

    if (hasIkDebit) return IK_DEBIT_DELIVERY_FEE;

    return DELIVERY_FEE;
  };

  const calculateDiscountPrice = (cost: {
    checkoutChargeAmount: {
      amount: string;
    };
    subtotalAmount: {
      amount: string;
    };
  }) => {
    const chargeAmount = parseFloat(cost.checkoutChargeAmount.amount);
    const subTotalPrice = parseFloat(cost.subtotalAmount.amount);

    return chargeAmount - subTotalPrice;
  };

  const updateCart = (lineItems: any[], shopifyCheckout: CartResponse) => {
    const cost = shopifyCheckout?.cost || {
      checkoutChargeAmount: { amount: '0' },
    };
    const totalPrice =
      (typeof shopifyCheckout?.cost.totalAmount?.amount === 'string'
        ? parseFloat(shopifyCheckout?.cost.totalAmount?.amount)
        : shopifyCheckout?.cost.totalAmount?.amount) || 0;
    const subTotalPrice =
      parseFloat(shopifyCheckout?.cost?.subtotalAmount?.amount) || 0;
    const totalTax = calculateTotalTax(totalPrice);
    const deliveryPrice = calculateDeliveryPrice(lineItems);

    const discountPrice = calculateDiscountPrice(cost);
    const subTotalCalc = subTotalPrice - totalTax - deliveryPrice;
    const cartData = {
      id: shopifyCheckout.id,
      lineItems,
      totalPrice: totalPrice + deliveryPrice,
      totalPriceWithoutDelivery: totalPrice,
      totalTax: totalTax,
      subtotalPrice: subTotalCalc > 0 ? subTotalCalc : 0,
      deliveryPrice: deliveryPrice,
      discountPrice: discountPrice,
      discountCode: shopifyCheckout?.discountCodes?.[0]?.applicable
        ? shopifyCheckout?.discountCodes?.[0]?.code
        : '',
      email: shopifyCheckout.buyerIdentity?.email,
    };

    setCart(cartData);

    return cartData;
  };

  const updateShopifyShippingAddress = async (
    userDetails: UserFormType,
    deliveryAddress: DeliveryFormType
  ) => {
    if (cartId === null || cart === null) return;

    const shippingAddress = {
      address1: deliveryAddress.deliveryStreet,
      city: deliveryAddress.deliveryCity,
      province: deliveryAddress.deliveryProvince,
      zip: deliveryAddress.deliveryPostCode,
      country: 'South Africa',
      firstName: userDetails.fullName.split(' ')[0],
      lastName: userDetails.fullName.split(' ')[1],
      phone: userDetails.cellNumber,
      countryCode: 'ZA',
    };

    const shopifyCheckoutUpdated = await addDeliveryAddress(
      cartId,
      shippingAddress
    );
    if (!shopifyCheckoutUpdated) {
      return;
    }

    updateCart(cart.lineItems, shopifyCheckoutUpdated);
    return shopifyCheckoutUpdated;
  };

  const fetchShopifyDraftOrder = async (
    draftOrderId: string,
    createCartItems: boolean = false
  ) => {
    try {
      if (!draftOrderId) {
        throw new Error('Draft order ID is required');
      }
      const proxyUrl = '/api/shopify';
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      const data = {
        query: `
          query getDraftOrder($draftOrderId: ID!) {
            draftOrder(id: $draftOrderId) {
              id
              email
              invoiceUrl
              shippingAddress {
                address1
                address2
                city
                province
                zip
                country
                firstName
                lastName
                phone
              }
              totalPriceSet {
                shopMoney {
                  amount
                  currencyCode
                }
              }
              subtotalPriceSet {
                shopMoney {
                  amount
                  currencyCode
                }
              }
              totalDiscountsSet {
                shopMoney {
                  amount
                  currencyCode
                }
              }
              discountCodes
              lineItems(first: 20) {
                edges {
                  node {
                    id
                    title
                    quantity
                    variantTitle
                    sku
                    variant {
                      id
                      title
                      sku
                    }
                    product {
                      id
                      handle
                      title
                      variants(first: 20) {
                        edges {
                          node {
                            id
                            title
                            sku
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          draftOrderId: draftOrderId,
        },
      };

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      };

      const response = await fetch(proxyUrl, requestOptions);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Fetch error:', response.status, errorText);
        throw new Error(`Fetch error: ${response.status} - ${errorText}`);
      }

      const json = await response.json();
      const draftOrder = json.data.draftOrder;

      if (!draftOrder) {
        throw new Error('Draft order not found');
      }

      const shopifyLineItems = draftOrder.lineItems.edges.map((item: any) => {
        const node = item.node;

        let variantGid = node.variant?.id;

        if (!variantGid && node.product) {
          if (node.variantTitle && node.product.variants?.edges) {
            const matchedVariant = node.product.variants.edges.find(
              (v: any) => v.node.title === node.variantTitle
            );
            if (matchedVariant) {
              variantGid = matchedVariant.node.id;
            }
          }

          if (!variantGid && node.sku && node.product.variants?.edges) {
            const matchedVariant = node.product.variants.edges.find(
              (v: any) => v.node.sku === node.sku
            );
            if (matchedVariant) {
              variantGid = matchedVariant.node.id;
            }
          }
          if (!variantGid && node.product.variants?.edges?.length === 1) {
            variantGid = node.product.variants.edges[0].node.id;
          }
        }

        if (!variantGid) {
          console.warn(
            `Could not find variant ID for product: ${node.product?.handle || node.title}`
          );
        }

        return {
          productHandle: node.product?.handle,
          variantGid: variantGid,
          quantity: node.quantity,
          id: node.id,
          sku: node.sku,
          title: node.title,
        };
      });

      const validShopifyLineItems = shopifyLineItems.filter(
        (item: any) => item.productHandle
      );

      const fetchedLineItems = await Promise.all(
        validShopifyLineItems.map((item: any) => getProduct(item.productHandle))
      );

      const lineItems = fetchedLineItems.map((product, index) => {
        let variantGid = validShopifyLineItems[index].variantGid;

        if (
          !variantGid &&
          product &&
          product.variants &&
          product.variants.length > 0
        ) {
          variantGid = product.variants[0].variantGid;
        }

        return {
          product: {
            ...(product as Product),
            variantGid: variantGid,
          },
          quantity: validShopifyLineItems[index].quantity,
          id: validShopifyLineItems[index].id,
          variantGid: variantGid,
        };
      });

      const validLineItems = lineItems.filter(
        (item: any) => item.product?.variantGid
      );

      if (createCartItems) {
        if (draftOrder.shippingAddress) {
          const userDetails = {
            fullName:
              draftOrder.shippingAddress.firstName +
              ' ' +
              draftOrder.shippingAddress.lastName,
            email: draftOrder.email,
            cellNumber: draftOrder.shippingAddress.phone,
          };

          setUserAtom(userDetails);
        }

        const variants = validLineItems.map((item: any) => ({
          variantId: item.product.variantGid,
          quantity: item.quantity,
        }));

        if (variants.length > 0) {
          try {
            await addItems(variants, true);
          } catch (error) {
            console.error('Error adding items to cart:', error);
          }
        } else {
          console.error('No items to add to cart');
        }

        const shopifyResponse = await getCart(cartId);

        if (!shopifyResponse) {
          throw new Error('Failed to fetch cart');
        }

        if (draftOrder.discountCodes && draftOrder.discountCodes.length > 0) {
          try {
            await addDiscountCode(
              shopifyResponse.id,
              draftOrder.discountCodes[0]
            );
          } catch (discountError) {
            console.warn('Could not apply discount code:', discountError);
          }
        }

        if (draftOrder.shippingAddress) {
          const shippingAddress = {
            address1: draftOrder.shippingAddress.address1,
            address2: draftOrder.shippingAddress.address2,
            city: draftOrder.shippingAddress.city,
            province: draftOrder.shippingAddress.province,
            zip: draftOrder.shippingAddress.zip,
            country: draftOrder.shippingAddress.country || 'South Africa',
            firstName: draftOrder.shippingAddress.firstName,
            lastName: draftOrder.shippingAddress.lastName,
            phone: draftOrder.shippingAddress.phone,
            countryCode: 'ZA',
          };

          try {
            await addDeliveryAddress(shopifyResponse.id, shippingAddress);
          } catch (addressError) {
            console.warn('Could not add delivery address:', addressError);
          }
        }

        const updatedCart = await getCart(shopifyResponse.id);
        if (!updatedCart) {
          throw new Error('Failed to fetch updated cart');
        }

        await updateCart(validLineItems, updatedCart);
      }

      return {
        email: draftOrder.email,
        lineItems: validLineItems,
        totalPrice: draftOrder.totalPriceSet?.shopMoney?.amount || '0',
      };
    } catch (error) {
      datadogLogs.logger.debug('Checkout error', {
        message: error,
      });
      console.error('Error creating draft order:', error);
      throw error; // Rethrow the error if needed
    }
  };

  const createShopifyOrder = async (
    cart: CartType,
    userDetails: UserFormType,
    deliveryAddress: DeliveryFormType,
    billingAddress: BillingFormType
  ) => {
    try {
      const proxyUrl = '/api/shopify';
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      const orderDetails = {
        input: {
          customAttributes: { key: 'ikShopDirectOrder', value: 'true' },
          tags: ['IKHOKHA_HUBSPOT_CART_2_0'],
          lineItems: cart.lineItems.map((item) => ({
            // The tymebank card swap doesn't match the lineItemType
            // @ts-ignore
            variantId: item?.variantGid,
            quantity: item.quantity,
          })),
          shippingAddress: {
            address1: deliveryAddress.deliveryStreet,
            address2: deliveryAddress.deliveryComplex,
            city: deliveryAddress.deliveryCity,
            province: deliveryAddress.deliveryProvince,
            zip: deliveryAddress.deliveryPostCode,
            country: 'South Africa',
            firstName: userDetails.fullName.split(' ')[0],
            lastName: userDetails.fullName.split(' ')[1],
            phone: userDetails.cellNumber,
          },
          billingAddress: {
            address1:
              billingAddress?.billingStreet ?? deliveryAddress.deliveryStreet,
            address2:
              billingAddress?.billingComplex ?? deliveryAddress.deliveryComplex,
            city: billingAddress?.billingCity ?? deliveryAddress.deliveryCity,
            province:
              billingAddress?.billingProvince ??
              deliveryAddress.deliveryProvince,
            zip:
              billingAddress?.billingPostCode ??
              deliveryAddress.deliveryPostCode,
            country: 'South Africa',
            firstName: userDetails.fullName.split(' ')[0],
            lastName: userDetails.fullName.split(' ')[1],
            phone: userDetails.cellNumber,
          },
          email: userDetails.email,
          note: '',
          appliedDiscount: {
            amount: cart.discountPrice ?? 0,
            description: 'Discount',
            title: `${cart.discountCode}`,
            value: cart.discountPrice ?? 0,
            valueType: 'FIXED_AMOUNT',
          },
          shippingLine: {
            title: 'Standard Shipping',
            price: cart.deliveryPrice || 0,
          },
        },
      };

      const data = {
        query: `mutation draftOrderCreate($input: DraftOrderInput!) {
          draftOrderCreate(input: $input) {
            userErrors {
              field
              message
            }
            draftOrder {
              id
              status
              invoiceUrl
              email
              totalPrice
              lineItems(first: 2) {
                edges {
                  node {
                    image {
                      transformedSrc
                    }
                  }
                }
              }
            }
          }
        }`,
        variables: orderDetails,
      };

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      };

      const response = await fetch(proxyUrl, requestOptions);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Fetch error:', response.status, errorText);
        throw new Error(`Fetch error: ${response.status} - ${errorText}`);
      }
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      // Handle errors based on your application requirements
      console.error('Error creating draft order:', error);
      datadogLogs.logger.debug('Checkout error', {
        message: error,
      });
      throw error; // Rethrow the error if needed
    }
  };

  const calculateShopifyOrder = async (
    cart: CartType,
    userDetails: UserFormType,
    deliveryAddress: DeliveryFormType,
    billingAddress: BillingFormType
  ) => {
    try {
      const proxyUrl = '/api/shopify';
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      const orderDetails = {
        input: {
          customAttributes: { key: 'ikShopDirectOrder', value: 'true' },
          tags: ['IKHOKHA_HUBSPOT_CART_2_0'],
          lineItems: cart.lineItems.map((item) => ({
            variantId: item.product.variantGid,
            quantity: item.quantity,
          })),
          shippingAddress: {
            address1: deliveryAddress.deliveryStreet,
            city: deliveryAddress.deliveryCity,
            province: deliveryAddress.deliveryProvince,
            zip: deliveryAddress.deliveryPostCode,
            country: 'South Africa',
            firstName: userDetails.fullName.split(' ')[0],
            lastName: userDetails.fullName.split(' ')[1],
            phone: userDetails.cellNumber,
          },
          billingAddress: {
            address1:
              billingAddress?.billingStreet ?? deliveryAddress.deliveryStreet,
            city: billingAddress?.billingCity ?? deliveryAddress.deliveryCity,
            province:
              billingAddress?.billingProvince ??
              deliveryAddress.deliveryProvince,
            zip:
              billingAddress?.billingPostCode ??
              deliveryAddress.deliveryPostCode,
            country: 'South Africa',
            firstName: userDetails.fullName.split(' ')[0],
            lastName: userDetails.fullName.split(' ')[1],
            phone: userDetails.cellNumber,
          },
          email: userDetails.email,
          note: '',
          appliedDiscount: {
            amount: cart.discountPrice ?? 0,
            description: 'Discount',
            title: `${cart.discountCode}`,
            value: cart.discountPrice ?? 0,
            valueType: 'FIXED_AMOUNT',
          },
        },
      };

      const data = {
        query: `mutation draftOrderCalculate($input: DraftOrderInput!) {
          draftOrderCalculate(input: $input) {
            calculatedDraftOrder {
              subtotalPrice
              totalPrice
              totalShippingPrice
              totalTax
              availableShippingRates {
                  handle
                  title
                  price {
                      amount
                  }
              }
            }
            userErrors {
              field
              message
            }
          }
        }`,
        variables: orderDetails,
      };

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      };

      const response = await fetch(proxyUrl, requestOptions);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Fetch error:', response.status, errorText);
        throw new Error(`Fetch error: ${response.status} - ${errorText}`);
      }

      const json = await response.json();

      return json;
    } catch (error) {
      // Handle errors based on your application requirements
      console.log('Error calculating shopify order:', error);
      throw error; // Rethrow the error if needed
    }
  };

  const addNoteToDraftOrder = async (orderId: string) => {
    try {
      const proxyUrl = '/api/shopify';
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      const data = {
        query: `mutation draftOrderUpdate($id: ID!, $input: DraftOrderInput!) {
          draftOrderUpdate(id: $id, input: $input) {
            draftOrder {
              id
            }
            userErrors {
              field
              message
            }
          }
        }`,
        variables: {
          id: `${orderId}`,
          input: {
            note: `${orderId}`,
          },
        },
      };

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      };

      const response = await fetch(proxyUrl, requestOptions);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Fetch error:', response.status, errorText);
        throw new Error(`Fetch error: ${response.status} - ${errorText}`);
      }

      const json = await response.json();

      return json;
    } catch (error) {
      // Handle errors based on your application requirements
      console.error('Error updating shopify draft order:', error);
      throw error; // Rethrow the error if needed
    }
  };

  const updateShopifyOrderAmountById = async (
    orderId: string,
    deliveryAmount: number,
    deliveryHandle: string
  ) => {
    try {
      const proxyUrl = '/api/shopify';
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      const data = {
        query: `mutation draftOrderUpdate($id: ID!, $input: DraftOrderInput!) {
          draftOrderUpdate(id: $id, input: $input) {
            draftOrder {
              id
            }
            userErrors {
              field
              message
            }
          }
        }`,
        variables: {
          id: `${orderId}`,
          input: {
            shippingLine: {
              price: deliveryAmount,
              shippingRateHandle: deliveryHandle,
              title: 'Door to Door',
            },
            note: `${orderId}`,
          },
        },
      };

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      };

      const response = await fetch(proxyUrl, requestOptions);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Fetch error:', response.status, errorText);
        throw new Error(`Fetch error: ${response.status} - ${errorText}`);
      }

      const json = await response.json();

      return json;
    } catch (error) {
      // Handle errors based on your application requirements
      console.error('Error updating shopify draft order:', error);
      throw error; // Rethrow the error if needed
    }
  };
  const updateLineItems = async (lineItemUpdates: any[]) => {
    try {
      // Fetch the cart
      if (cartId === null) {
        return;
      }

      const lineItemsWithoutProduct = lineItemUpdates.map(
        ({ product, ...rest }) => rest
      );

      // Update the line items
      const updatedCart = await updateCartItem(cartId, lineItemsWithoutProduct);

      if (!updatedCart) {
        return;
      }

      return updateCart(lineItemUpdates, updatedCart);
    } catch (error) {
      console.error('Error updating cart:', error);
      throw error;
    }
  };

  const swapLineItems = async (lineItemUpdates: any[]) => {
    for (const lineItem of lineItemUpdates) {
      await removeItem(lineItem.id);
    }

    const newLineItems = lineItemUpdates.map((item) => ({
      variantId: item.variantId,
      quantity: item.quantity,
    }));
    await addItems(newLineItems);

    const shopifyCheckout = await getCart(cartId);

    if (!shopifyCheckout) {
      return;
    }

    const lineItems = await fetchLineItems(shopifyCheckout?.lines || []);

    return updateCart(lineItems, shopifyCheckout);
  };

  const setShopifyOrderAsPaid = async (orderId: string) => {
    try {
      const proxyUrl = '/api/shopify';
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      const data = {
        query: `mutation draftOrderComplete($id: ID!) {
          draftOrderComplete(id: $id) {
            draftOrder {
              id
              status
              invoiceUrl
              email
              totalPrice
              lineItems(first: 2) {
                edges {
                  node {
                    image {
                      transformedSrc
                    }
                  }
                }
              }
            }
            userErrors {
              field
              message
            }
          }
        }`,
        variables: {
          id: `${orderId}`,
        },
      };

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      };

      const response = await fetch(proxyUrl, requestOptions);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Fetch error:', response.status, errorText);
        throw new Error(`Fetch error: ${response.status} - ${errorText}`);
      }

      const json = await response.json();

      return json;
    } catch (error) {
      // Handle errors based on your application requirements
      console.error('Error marking order as paid:', error);
      throw error; // Rethrow the error if needed
    }
  };

  return {
    addItem,
    addItems,
    updateItem,
    removeItem,
    addDiscount,
    fetchCart,
    updateLineItems,
    updateShopifyShippingAddress,
    createShopifyOrder,
    calculateShopifyOrder,
    updateShopifyOrderAmountById,
    fetchShopifyDraftOrder,
    setShopifyOrderAsPaid,
    isRemovingOneItem,
    isAddingOneItem,
    isRemovingLineItem,
    addNoteToDraftOrder,
    productIsDebitCard,
    fetchLineItems,
    swapLineItems,
  };
};

export default useCart;
